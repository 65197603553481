import React from "react";
import { useNavigate } from "react-router-dom";

const Header = ({ onLogout }) => {
    const navigate = useNavigate();
    return (
        <div style={styles.header}>
            <h1 style={styles.title}>Waffle Wednesday</h1>
            <button style={styles.logoutButton} onClick={() => {
                    onLogout();
                    navigate("/login");
                }}
            >
                Logout
            </button>
        </div>
    );
};

const styles = {
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        backgroundColor: "#333",
        color: "white",
    },
    title: {
        margin: 0,
        fontSize: "24px",
    },
    logoutButton: {
        backgroundColor: "#ff4d4d",
        color: "white",
        border: "none",
        padding: "10px 15px",
        borderRadius: "5px",
        cursor: "pointer",
    },
};

export default Header;
