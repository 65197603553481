import React from 'react';

const Feed = () => {
    return (
        <div style={styles.container}>
            <p style={styles.message}>Feed will go here</p>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
    },
    message: {
        fontSize: '24px',
        color: '#555',
    },
};

export default Feed;
